<template>
  <div class="modal-container">
    <v-btn class="close-btn" icon @click="close">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div>
      <span class="share-free-trial-title">{{
        $t('navbarActions.shareFreeTrial.shareTrial')
      }}</span>
    </div>
    <div class="add-heart__title">
      <v-icon small :color="'#2196F3'">{{ heartIcon }}</v-icon>
      <div class="add-heart__title_subtext">
        {{ $t('fingerprint.weDonateForEachFingerprint') }}
        <span class="learn_more" @click="openShareMenu">{{
          $t('fingerprint.learnMore')
        }}</span>
      </div>
    </div>
    <div v-if="step === 1">
      <v-container>
        <v-row>
          <img
            class="image-invite"
            src="/assets/img/trial/share_free_trial_donate.svg"
            alt
          />
        </v-row>
      </v-container>

      <v-form
        ref="form"
        v-model="isNotValid"
        lazy-validation
        @submit.prevent="submit"
      >
        <v-container class="email-list-container">
          <v-row>
            <v-col class="name-col">
              <div class="message-label">{{ $t('profile.firstname') }}</div>
            </v-col>
            <v-col class="name-col">
              <div class="message-label">{{ $t('profile.lastname') }}</div>
            </v-col>
            <v-col class="email-col">
              <div class="message-label">
                {{ $t('navbarActions.addColleagueDetail.email') }}
              </div>
            </v-col>
            <v-col class="name-col">
              <NewFeatureBadge
                module-name="sharetrialform"
                :feature-id="'sharetrialformexternalaudience_id'"
                :offsetX="'-5'"
                :offsetY="'22'"
              >
                <div class="message-label">
                  {{ $t('fingerprint.exploreFingerprint.company') }}
                </div>
              </NewFeatureBadge>
            </v-col>
            <v-col class="close-col"></v-col>
          </v-row>
          <v-row v-for="(colleague, index) in colleaguesList" :key="index">
            <v-col class="name-col">
              <v-text-field
                :rules="firstnameRequired"
                :placeholder="$t('fingerprint.colleagueFirstName')"
                required
                v-model="colleaguesList[index].firstname"
                :id="'name-input-' + index"
                :data-pendo-id="index == 0 ? 'trial-colleague-firstname' : null"
              ></v-text-field>
            </v-col>
            <v-col class="name-col">
              <v-text-field
                :rules="lastnameRequired"
                :placeholder="$t('fingerprint.colleagueLastName')"
                required
                v-model="colleaguesList[index].lastname"
                :id="'lastname-input-' + index"
              ></v-text-field>
            </v-col>
            <v-col class="email-col">
              <v-text-field
                :rules="emailRules"
                :placeholder="$t('generate.collegueEmail')"
                required
                @change="(value) => handleEmailChange(value, index)"
                @blur="(e) => handleEmailBlur(e.target.value, index)"
                @keydown.enter="addColleague('enter')"
                v-model="colleaguesList[index].email"
                :id="'email-input-' + index"
              ></v-text-field>
            </v-col>
            <v-col class="name-col">
              <v-text-field
                :rules="companynameRequired"
                :placeholder="$t('generate.colleagueCompany')"
                required
                v-model="colleaguesList[index].company"
                :disabled="colleaguesList[index].isCompanyDisabled"
                :loading="colleaguesList[index].isCompanyNameLoading"
                :id="'company-input-' + index"
              ></v-text-field>
            </v-col>
            <v-col class="close-col">
              <v-btn
                v-if="colleaguesList.length > 1"
                class=""
                icon
                @click="removeColleague(index)"
              >
                <v-icon dark> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-btn
              text
              color="#21a7e0"
              class="add-btn"
              @click="addColleague"
              :disabled="colleaguesList.length >= 10"
            >
              <v-icon dark :color="'#21a7e0'"> mdi-plus </v-icon>
              {{ $t('profile.addColleague') }}
            </v-btn>
          </v-row>
          <v-row>
            <v-col cols="12" class="textarea-container">
              <div class="message-label">{{ $t('profile.message') }}</div>
              <div class="textarea">
                <textarea
                  v-model="message"
                  :placeholder="$t('profile.addANote')"
                />
              </div>
            </v-col>
          </v-row>
          <div
            v-if="
              isNotValid &&
              colleaguesList.filter((colleague) => {
                if (
                  colleague.email !== '' &&
                  colleague.firstname !== '' &&
                  colleague.lastname !== ''
                ) {
                  return {
                    ...colleague,
                  };
                }
              }).length > 0
            "
            @click="openEmailPreviewModal"
            class="preview-email"
          >
            {{ $t('navbarActions.addColleagueDetail.preview') }}
          </div>
          <v-row>
            <v-btn
              class="share-btn primary-button"
              rounded
              color="#21a7e0"
              height="48"
              min-width="134"
              dark
              :loading="isLoading"
              @click="handleShare"
              data-pendo-id="shareButton"
              :disabled="
                !isNotValid ||
                colleaguesList.every((colleague) => {
                  return (
                    colleague.email == '' ||
                    colleague.firstname == '' ||
                    colleague.lastname == ''
                  );
                })
              "
            >
              {{ $t('common.share') }}
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </div>
    <div v-if="step === 2">
      <v-container>
        <v-row class="align-center">
          <img
            class="image-success-invite"
            src="/assets/img/trial/share_free_trial_donate.svg"
            alt
          />
        </v-row>
      </v-container>
      <v-container v-if="sentOutInvitesUsersData.length">
        <v-row class="invite-info-detail">
          <span>
            <img
              class="invite-image"
              src="/assets/img/feather_check-circle.svg"
              alt
            />
          </span>
          <span>{{ $t('generate.InvitesSent') }}</span>
        </v-row>
      </v-container>

      <v-container v-if="sentOutInvitesUsersData.length" class="users-list">
        <v-row
          v-for="(colleague, index) in sentOutInvitesUsersData"
          :key="index"
          class="item-row space-between"
        >
          <div>
            {{
              colleague &&
              `${colleague.audFirstName} ${colleague.audLastName} (${colleague.audEmail})`
            }}
          </div>
          <div class="copy-btn-container">
            <v-btn
              color="primary"
              class="copy-btn"
              text
              @click="handleCopy(colleague, index)"
            >
              <v-icon dark> mdi-content-copy </v-icon>
              {{
                colleague && sentOutInvitesUsersData[index].isCopied
                  ? $t('navbarActions.shareFreeTrial.copied')
                  : $t('userUploadPrezentation.copyLink')
              }}
            </v-btn>
          </div>
        </v-row>
      </v-container>

      <v-container v-if="existingUsersData.length">
        <v-row class="invite-info-detail">
          <span>
            <img
              class="invite-image"
              src="/assets/img/feather_check-circle.svg"
              alt
            />
          </span>
          <span>{{ $t('generate.membersAlreadyUsingPrezent') }}</span>
        </v-row>
      </v-container>

      <v-container v-if="existingUsersData.length" class="users-list">
        <v-row
          v-for="(colleague, index) in existingUsersData"
          :key="index"
          class="item-row space-between"
        >
          <div>
            <a
              :class="colleague.fpVisibleToCompany ? 'clickable' : 'disabled'"
              @click="
                colleague.fpVisibleToCompany
                  ? onFingerprintClick(colleague)
                  : ''
              "
              >{{
                colleague &&
                `${colleague.audFirstName} ${colleague.audLastName} (${colleague.audEmail})`
              }}</a
            >
          </div>
        </v-row>
      </v-container>

      <v-container v-if="nonRegisteredCompanyUsersData.length">
        <v-row class="invite-info-detail non-registered">
          <span>
            <img class="invite-image" src="/assets/img/warning-line.svg" alt />
          </span>
          <span>{{ $t('generate.companiesAddedToWaitlist') }}</span>
        </v-row>
      </v-container>

      <v-container
        v-if="nonRegisteredCompanyUsersData.length"
        class="users-list"
      >
        <v-row
          v-for="(colleague, index) in nonRegisteredCompanyUsersData"
          :key="index"
          class="item-row space-between"
        >
          <div>
            {{
              colleague &&
              `${colleague.audFirstName} ${colleague.audLastName} (${colleague.audEmail})`
            }}
          </div>
        </v-row>
      </v-container>

      <v-container>
        <v-row class="item-row">
          {{ $t('generate.likeToShareFreeTrial') }}
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-btn
            class="share-btn primary-button"
            rounded
            color="#21a7e0"
            height="48"
            min-width="134"
            @click="handleContinue"
          >
            {{ $t('common.continue') }}
          </v-btn>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { mdiCardsHeart } from '@mdi/js';
import { getFileURL } from '@/utils/calyrex';
import TrialApi from '../../API/trial-api';
import { isEmojiExist, isOutsideCompanyEmail } from '../utils';
import { getDonationDetails, sendEmail } from '../../utils/api-helper';
import LearnMorePop from '../Main/PrezentWithHeart/LearnMorePop.vue';
import EmailPreviewModal from '../common/EmailPreviewModal.vue';
import { capitalizeFirstLetter } from '../../utils/common';
import AudienceAPI from '../../API/audience-api';
import NewFeatureBadge from '../common/NewFeatureBadge.vue';
import { trackGeneralEvents } from '../common/Analytics/GeneralEvents';
import {
  TD_ORIGIN_FINGERPRINT,
  TD_PLACEMENT,
  TD_SHARE_THE_GIFT_OF_FINGERPRINT,
  TD_AUD_EMAIL,
  TD_AUD_FIRST_NAME,
  TD_AUD_LAST_NAME,
  shareFingerPrintVsPlacement,
  TD_SHARE_FINGERPRINT_NAVBAR,
} from '../common/Analytics/MatomoTrackingDataHelper';

const EMPTY_COLLEAGUE = {
  email: '',
  firstname: '',
  lastname: '',
  company: '',
  isCompanyDisabled: true,
  isCompanyNameLoading: false,
};

export default {
  name: 'ShareFreeTrailNew',
  props: {
    close: {
      type: Function,
      default: () => ({}),
    },
    origin: {
      type: String,
      default: '',
    },
  },
  components: {
    NewFeatureBadge,
  },
  mixins: [TrialApi],
  emits: ['subModalOpen'],
  data() {
    return {
      isLoading: false,
      isNotValid: true,
      message: '',
      menuPosition: {
        x: 0,
        y: 0,
      },
      heartIcon: mdiCardsHeart,
      showShare: false,
      colleaguesList: [{ ...EMPTY_COLLEAGUE }, { ...EMPTY_COLLEAGUE }],
      resultData: [],
      step: 1,
      contributors: [],
      totalCount: 0,
      emailRules: [
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
        (v) => !!v || this.$t('warnings.emailRequired'),
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v,
          ) || this.$t('warnings.emailMustBeValid'),
        (v) =>
          isOutsideCompanyEmail(
            this.isEmailOutsideCompanyEnabled,
            this.currentUser.user.id,
            v,
            this.$t('warnings.enterAWorkEmail'),
          ),
        (v) =>
          this.isDuplicateEmail(v) || this.$t('warnings.emailAlreadyEntered'),
      ],
      firstnameRequired: [
        (v) => !!v || this.$t('warnings.firstNameRequired'),
        (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
      ],
      lastnameRequired: [
        (v) => !!v || this.$t('warnings.lastNameRequired'),
        (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
      ],
      companynameRequired: [
        (v) => !!v || this.$t('warnings.companyNameRequired'),
        (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
      ],

      sentOutInvitesUsersData: [],
      existingUsersData: [],
      nonRegisteredCompanyUsersData: [],
    };
  },

  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('users', [
      'getCompanyDomain',
      'isEmailOutsideCompanyEnabled',
    ]),
    getLoading() {
      return this.loading;
    },
    defaultColleaguesList() {
      return [{ ...EMPTY_COLLEAGUE }, { ...EMPTY_COLLEAGUE }];
    },
  },
  methods: {
    addColleague(from) {
      if (this.colleaguesList.length < 10) {
        this.colleaguesList.push({ ...EMPTY_COLLEAGUE });

        setTimeout(() => {
          const element = this.$el.querySelector(
            `#name-input-${this.colleaguesList.length - 1}`,
          );

          if (element) {
            if (from === 'enter') {
              element.focus();
            }
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
            });
          }
        }, 100);
      }
    },
    removeColleague(index) {
      if (this.colleaguesList.length > 1) {
        this.colleaguesList = this.colleaguesList.filter((_, i) => i !== index);
      }
    },
    handleEmailChange(value, index) {
      this.colleaguesList[index].email = value.toLowerCase();
    },
    handleEmailBlur(value, index) {
      if (this.isEmailValid(value)) {
        const companyDomain = value.substring(value.lastIndexOf('@') + 1);
        this.colleaguesList[index].isCompanyNameLoading = true;

        AudienceAPI.methods
          .getCompanyNameByDomain(companyDomain)
          .then((data) => {
            if (data && data.companyName) {
              this.colleaguesList[index].company = data.companyName;
              this.colleaguesList[index].isCompanyDisabled = true;
            } else {
              const cNameArray = companyDomain.split('.');
              const cName =
                cNameArray && cNameArray.length ? cNameArray[0] : '';

              this.colleaguesList[index].company = capitalizeFirstLetter(cName);
              this.colleaguesList[index].isCompanyDisabled = false;
            }
            this.colleaguesList[index].isCompanyNameLoading = false;
          })
          .catch((e) => {
            console.log('error', e);
            this.colleaguesList[index].isCompanyNameLoading = false;
          });
      } else {
        this.colleaguesList[index].isCompanyDisabled = false;
      }
    },
    getMatomoOtherData() {
      if (this.origin && this.origin === TD_ORIGIN_FINGERPRINT) {
        return {
          [TD_PLACEMENT]: TD_SHARE_THE_GIFT_OF_FINGERPRINT,
        };
      }
      if (this.origin && this.origin === 'Generate Inisghts') {
        return { [TD_PLACEMENT]: 'Generate Inisghts' };
      }
      return {};
    },
    handleShare() {
      this.colleaguesList.forEach((item, i) => {
        if (!item.email && i !== 0) {
          this.removeColleague(i);
        }
      });
      setTimeout(() => {
        const isValid = this.$refs.form.validate();
        this.isNotValid = !isValid;

        if (isValid) {
          this.isLoading = true;
          const { id, fullName } = this.currentUser.user;
          const payload = this.colleaguesList.map((item) => {
            const { email, firstname, lastname, company: companyname } = item;
            return {
              email,
              firstname,
              lastname,
              companyname,
              requester: id,
              requester_fullname: fullName,
              message: this.message,
            };
          });
          trackGeneralEvents.sharetrialsShare(
            this.currentUser,
            this.colleaguesList,
            this.getMatomoOtherData(),
          );
          TrialApi.methods
            .trialRegistration(payload)
            .then((resp) => {
              if (!resp.error) {
                this.resultData = resp.data;
                this.sentOutInvitesUsersData = this.resultData.filter(
                  (result) =>
                    !result.userAlreadyExist &&
                    result.companyRegistered &&
                    result.signUpLink,
                );
                this.existingUsersData = this.resultData.filter(
                  (result) => result.userAlreadyExist,
                );

                this.nonRegisteredCompanyUsersData = this.resultData.filter(
                  (result) =>
                    !result.userAlreadyExist && !result.companyRegistered,
                );
                this.step = 2;
              } else {
                // TODO: error handle
              }
            })
            .catch((error) => console.log(error))
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          setTimeout(() => {
            const element = this.$el.querySelector(`.v-messages__message`);

            if (element)
              element.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
              });
          }, 100);
        }
      }, 500);
    },
    subModalClosed() {
      this.$emit('subModalOpen', true);
    },
    async openShareMenu() {
      trackGeneralEvents.sharetrialsIconclickLearnmore(
        this.currentUser,
        this.getMatomoOtherData(),
      );
      await getDonationDetails()
        .then(async (res) => {
          if (res.data.contributors && res.data.contributors.length > 0) {
            await res.data.contributors.forEach(async (learner) => {
              learner.profileImageUrl = '';
              if (learner.profileImage && !learner.profileImageUrl) {
                getFileURL(
                  null,
                  `public/${learner.profileImage}`,
                  null,
                  'storage',
                ).then((s3url) => {
                  learner.profileImageUrl = s3url;
                });
              }
            });
          }
          this.contributors = res.data.contributors;
          this.totalCount = Number(res.data.totalCount);
        })
        .catch((err) => console.log(err));
      this.$emit('subModalOpen', false);
      this.$modal.show(
        LearnMorePop,
        {
          contributors: this.contributors,
          totalCount: this.totalCount,
          onVisitSite: () => {
            trackGeneralEvents.sharetrialsIconclickLearnmoreVisitsite(
              this.currentUser,
              this.getMatomoOtherData(),
            );
          },
        },
        {
          name: 'LearnMorePop',
          classes: 'v-application',
          width: '520px',
          height: 'auto',
          styles: {
            borderRadius: '20px',
            padding: '20px',
            'max-height': '800px',
            'min-height': '300px',
            'overflow-y': 'auto',
          },
        },
        {
          'before-close': this.subModalClosed,
        },
      );
    },
    closeShareMenu() {
      this.showShare = false;
    },
    handleContinue() {
      this.colleaguesList = this.defaultColleaguesList;
      this.resultData = [];
      this.message = '';
      this.step = 1;
    },
    handleCopy(colleague, index) {
      const { signUpLink, audEmail } = colleague;
      if (signUpLink) {
        navigator.clipboard.writeText(signUpLink);
        this.sentOutInvitesUsersData[index] = { ...colleague, isCopied: true };
        this.$forceUpdate();
        trackGeneralEvents.shareTrialsShareLink(this.currentUser, {
          [TD_AUD_EMAIL]: colleague?.audEmail,
          [TD_AUD_FIRST_NAME]: colleague?.audFirstName,
          [TD_AUD_LAST_NAME]: colleague?.audLastName,
          [TD_PLACEMENT]:
            this.origin === 'top nav bar'
              ? shareFingerPrintVsPlacement[TD_SHARE_FINGERPRINT_NAVBAR]
              : TD_ORIGIN_FINGERPRINT,
        });
        try {
          this.shareTrialLink = signUpLink;
          const requester = `${this.currentUser.user.firstName} ${this.currentUser.user.lastName}`;
          const sub =
            `Hi Success Team,` +
            `${requester} (${this.currentUser.user.id}) ` +
            ` has copied trial share link`;

          sendEmail({
            type: 'email-share-request-new',
            meta: {
              subject: sub,
              audience: 'Success Team',
              requester,
              toAddress: 'test.trial@prezent.ai',
              shareLink: this.shareTrialLink,
              message:
                `${requester} (${this.currentUser.user.id}) ` +
                ` has copied trial share link for ${audEmail}`,
            },
          })
            .then((resp) => {
              console.log(resp);
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (err) {
          console.log(err);
        }
      }
    },
    isEmailDomainSame(v) {
      if (v) {
        const domain = this.currentUser.user.id.split('@')[1];
        const colleagueDomain = v.split('@')[1];
        return domain === colleagueDomain;
      }
      return true;
    },
    isDuplicateEmail(v) {
      if (v) {
        return !(
          this.colleaguesList.filter((item) => item.email === v).length > 1
        );
      }

      return true;
    },
    isEmailValid(v) {
      if (v) {
        return (
          this.emailRules[0](v) === true &&
          this.emailRules[1](v) === true &&
          this.emailRules[2](v) === true &&
          this.emailRules[3](v) === true
        );
      }
      return false;
    },
    openEmailPreviewModal() {
      this.shareTrialLink = `&lt;Unique link&gt;`;
      this.$emit('subModalOpen', false);
      this.$modal.show(
        EmailPreviewModal,
        {
          emailApiPayload: {
            type: 'trial-registration-new',
            meta: {
              toAddress: 'test.trial@prezent.ai',
              signupLink: this.shareTrialLink,
              firstName: this.currentUser.user.firstName,
              lastName: this.currentUser.user.lastName,
            },
          },
          heading: 'Trial invite email preview',
        },
        {
          name: 'EmailPreviewModal',
          classes: 'v-application',
          width: '520px',
          height: '600px',
          styles: {
            borderRadius: '20px',
            padding: '20px',
          },
        },
        {
          'before-close': this.subModalClosed,
        },
      );
    },
    onFingerprintClick(item) {
      this.$router.push(
        `/home/fingerprints?uuid=${item.uuid}&type=user`,
        () => {},
        () => {},
      );
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';

.modal-container {
  padding: 15px;

  .share-free-trial-title {
    color: $dark-default;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.02em;
  }

  .close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 10px 5px;
  }

  .email-list-container {
    max-height: 300px;
    overflow-y: scroll;

    .name-col {
      margin-right: 10px;
      min-width: 20%;
      padding: 0;

      .message-label {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .email-col {
      margin-right: 10px;
      min-width: 30%;
      padding: 0;

      .message-label {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .close-col {
      min-width: 4%;
    }
  }

  .add-btn,
  .col {
    padding: 0;
  }
  .textarea-container {
    width: 100%;
    margin: 20px 0 10px 0;

    .message-label {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: bold;
    }
    .textarea {
      min-width: 100%;

      textarea {
        box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
        padding: 10px;
        height: 75px;
        width: 100%;
      }
    }
  }
  .preview-email {
    color: #21a7e0;
    cursor: pointer;
    margin: 0px 0px 0px 4px;
  }

  .v-btn--disabled.v-btn--has-bg {
    background-color: rgba(0, 0, 0, 0.2) !important;
    color: rgba(255, 255, 255, 0.6) !important;
  }

  .image-invite {
    width: 100%;
    margin: 20px auto;
  }

  .align-center {
    display: flex;
    justify-content: center;
  }

  .space-between {
    display: flex;
    justify-content: space-between;
  }

  .item-row {
    margin: -6px -12px;
    font-size: 16px;
    align-items: center;
    text-transform: none;
  }

  .image-success-invite {
    margin: 20px 0px;
  }

  .invite-info-detail {
    align-items: center;
    font-size: 16px;
    margin-bottom: 0px;

    span {
      margin-right: 5px;
    }

    .invite-image {
      height: 24px;
      width: 24px;
    }
  }

  .non-registered {
    flex-wrap: nowrap;
    align-items: baseline;
  }

  .users-list {
    max-height: 160px;
    overflow: auto;
    .disabled {
      cursor: auto;
      text-decoration: none;
    }
  }

  .share-btn {
    margin-top: 10px;
    padding: 6px 24px;
    text-transform: none;
  }
  .add-btn {
    font-size: 16px;
    text-transform: none;
    letter-spacing: normal;
  }

  .copy-btn-container {
    min-width: 130px;
    text-transform: none;
    .copy-btn {
      text-transform: none;
    }
  }

  .row {
    width: 100%;
    margin: 0%;
  }
  .add-heart__title {
    font-size: 14.5px;
    color: gray;
    display: flex;
    margin-top: 5px;
    .add-heart__title_subtext {
      margin-top: -3.5px;
      margin-left: 5px;
    }
  }
  .learn_more {
    cursor: pointer;
    color: rgb(0, 149, 255);
  }
}
.v-menu__content {
  width: 490px;
  right: 20px;
  left: 0px;
  background: white;
  padding: 20px;
  border-radius: 15px;
  top: 60px;
}
</style>
