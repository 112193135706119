<template>
  <div class="modal-container">
    <v-btn class="close-btn" icon @click="close">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div>
      <span class="add-user-title">{{ $t('profile.addUsersToTeam') }}</span>
    </div>
    <div v-if="step === 1">
      <v-container>
        <v-row>
          <img
            class="image-invite"
            src="/assets/img/profile/add-multiple-users.svg"
            alt
          />
        </v-row>
      </v-container>

      <v-form
        ref="form"
        v-model="isNotValid"
        lazy-validation
        @submit.prevent="submit"
      >
        <v-container class="email-list-container">
          <v-row>
            <v-col class="name-col">
              <div class="message-label">{{ $t('profile.firstname') }}</div>
            </v-col>
            <v-col class="name-col">
              <div class="message-label">{{ $t('profile.lastname') }}</div>
            </v-col>
            <v-col class="email-col">
              <div class="message-label">
                {{ $t('navbarActions.addColleagueDetail.email') }}
              </div>
            </v-col>
            <v-col class="role-col">
              <div class="message-label">{{ $t('profile.role') }}</div>
            </v-col>
            <v-col class="close-col"></v-col>
          </v-row>
          <v-row v-for="(colleague, index) in colleaguesList" :key="index">
            <v-col class="name-col">
              <v-text-field
                :rules="firstnameRequired"
                :placeholder="$t('profile.firstname')"
                required
                v-model="colleaguesList[index].firstname"
                :id="'name-input-' + index"
              ></v-text-field>
            </v-col>
            <v-col class="name-col">
              <v-text-field
                :rules="lastnameRequired"
                :placeholder="$t('profile.lastname')"
                required
                v-model="colleaguesList[index].lastname"
                :id="'lastname-input-' + index"
              ></v-text-field>
            </v-col>
            <v-col class="email-col">
              <v-text-field
                :rules="
                  colleaguesList[index].existError &&
                  colleaguesList[index].existError.length > 0
                    ? []
                    : emailRules
                "
                :placeholder="$t('navbarActions.shareFreeTrial.workEmail')"
                required
                @keyup="(value) => handleEmailChange(value, index)"
                @keydown.enter="addColleague('enter')"
                v-model="colleaguesList[index].email"
                :id="'email-input-' + index"
                :loading="colleaguesList[index].loading"
                :error-messages="
                  colleaguesList[index].existError &&
                  colleaguesList[index].existError.length > 0
                    ? colleaguesList[index].existError
                    : ''
                "
              />
              <!--
              <div
                class="emailExistErrors"
                v-if="colleaguesList[index].existError.length > 0"
                :style="{
                  color: '#f70000',
                  'line-height': '12px',
                  margin: '-21px 0px 0px 0px',
                }"
              >
                {{ colleaguesList[index].existError }}
              </div>
              !-->
            </v-col>
            <v-col class="role-col colleague-role">
              <v-select
                :rules="userRoleRequired"
                v-model="colleaguesList[index].role"
                :items="accessLevel"
                item-text="name"
                :placeholder="$t('profile.colleagueRole')"
                :label="$t('profile.select')"
                return-object
                single-line
                :id="'role-input-' + index"
                attach
                :menu-props="
                  index === colleaguesList.length - 1
                    ? { top: true, offsetY: false }
                    : { top: false, offsetY: false }
                "
              />
            </v-col>
            <v-col class="close-col">
              <v-btn
                v-if="colleaguesList.length > 1"
                icon
                @click="removeColleague(index)"
              >
                <v-icon dark> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row v-if="licenseAvailable">
            <v-btn
              text
              color="primary"
              class="add-btn"
              @click="addColleague"
              :disabled="colleaguesList.length >= 10"
            >
              <v-icon dark> mdi-plus </v-icon>
              {{ $t('profile.addColleague') }}
            </v-btn>
          </v-row>
          <v-row v-else class="no-more-license">
            {{ $t('profile.youHaveOnly') }} {{ planLicenseAvailable }}
            {{ $t('profile.licensesAvailable') }}
          </v-row>
          <v-row>
            <v-col cols="12" class="textarea-container">
              <div class="message-label">{{ $t('profile.message') }}</div>
              <div class="textarea">
                <textarea
                  v-model="message"
                  :placeholder="$t('profile.addANote')"
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-btn
              class="share-btn primary-button"
              rounded
              color="#21a7e0"
              height="48"
              min-width="134"
              dark
              :loading="loading"
              @click="onAddUser"
              :disabled="
                !isNotValid ||
                colleaguesList.every((colleague) => {
                  return (
                    colleague.email == '' ||
                    colleague.firstname == '' ||
                    colleague.lastname == '' ||
                    colleague.role == ''
                  );
                })
              "
            >
              {{ $t('navbarActions.shareFreeTrial.sendEmail') }}
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </div>
    <div v-if="step === 2">
      <v-container>
        <v-row class="align-center">
          <img
            class="image-success-invite"
            src="/assets/img/profile/email-sent.svg"
            alt
          />
        </v-row>
      </v-container>
      <v-container>
        <div class="email-send">{{ $t('profile.emailsSent') }}</div>
        <div v-if="failedEntries.length" class="failed-users">
          <p style="text-align: center">
            {{ $t('profile.unableToAddUsers') }}
          </p>
          <div class="users-list">
            <p v-for="(entry, index) in failedEntries" :key="index">
              <span>{{ entry.firstName }}</span>
              <span>{{ entry.lastName }}</span>
              <span>({{ entry.id }})</span>
            </p>
          </div>
        </div>
        <div class="add-more-users-btn">
          <v-btn
            class="share-btn primary-button"
            rounded
            color="#21a7e0"
            height="48"
            min-width="134"
            @click="handleContinue"
          >
            {{ $t('profile.addMoreUsers') }}
          </v-btn>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import usersApi from '../../API/users-api';
import { isEmojiExist } from '../utils';
import { getExsitingAdminTeamUser } from '../../utils/api-helper';
import { AnalyticsHandler } from '../common/Analytics/AnalyticsHandler';
import { getFullName } from '../../utils/common';
import GreatDecisionModal from '../common/GreatDecisionModal.vue';
import { trackProfileEvents } from '../common/Analytics/ProfileEvents';

export default {
  name: 'addUsersNew',
  components: {},
  props: {
    planLicenseAvailable: {
      type: Number,
      required: true,
    },
    planLicenseLimit: {
      type: Number,
      required: true,
    },
    planLicenseUsed: {
      type: Number,
      required: true,
    },
    userAccessLevels: {
      type: Array,
      required: true,
    },
    close: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      isNotValid: true,
      message: '',
      colleaguesList: [
        {
          email: '',
          firstname: '',
          lastname: '',
          role: '',
          loading: false,
          existError: '',
        },
        {
          email: '',
          firstname: '',
          lastname: '',
          role: '',
          loading: false,
          existError: '',
        },
      ],
      failedEntries: [],
      step: 1,
      accessLevel: ['User', 'Admin'],
      emailRules: [
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
        (v) => !!v || this.$t('warnings.emailRequired'),
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v,
          ) || this.$t('warnings.emailMustBeValid'),
        (v) =>
          this.isDuplicateEmail(v) || this.$t('warnings.emailAlreadyEntered'),
        (v) =>
          this.getCompanyDomain.includes(v.substring(v.lastIndexOf('@') + 1)) ||
          this.isEmailDomainSame(v) ||
          this.$t('warnings.enterAWorkEmail'),
        // async (v) =>
        //   (await this.isEmailExist(v)) ||
        //   'Your colleague is already a user. Enter another colleague.',
      ],
      firstnameRequired: [
        (v) => !!v || this.$t('warnings.firstNameRequired'),
        (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
      ],
      lastnameRequired: [
        (v) => !!v || this.$t('warnings.lastNameRequired'),
        (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
      ],
      userRoleRequired: [(v) => !!v || this.$t('warnings.roleIsRequired')],
    };
  },
  mounted() {
    if (this.planLicenseAvailable === 1) {
      this.colleaguesList = [
        {
          email: '',
          firstname: '',
          lastname: '',
          role: '',
          existError: '',
          loading: false,
        },
      ];
    }
  },
  watch: {},
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('users', ['getCompanyDomain']),
    licenseAvailable() {
      return (
        this.planLicenseUsed + this.colleaguesList.length <
        this.planLicenseLimit
      );
    },
  },
  methods: {
    onAddUser() {
      if (this.planLicenseAvailable <= 0) {
        this.$modal.show(
          GreatDecisionModal,
          {},
          {
            name: 'GreatDecisionModal',
            width: '732px',
            height: '583px',
          },
        );
      } else {
        trackProfileEvents.profileAdminSettingsAddUsersSendEmail(
          this.currentUser,
          {},
        );
        this.colleaguesList.forEach((item, i) => {
          if (!item.firstname && !item.lastname && !item.email && !item.role) {
            this.removeColleague(i);
          }
        });
        setTimeout(() => {
          const isValid = this.$refs.form.validate();
          this.isNotValid = !isValid;
          if (isValid) {
            console.log(this.colleaguesList);
            this.loading = true;
            for (let user = 0; user < this.colleaguesList.length; user++) {
              AnalyticsHandler.AdminAddedNewUser(
                this.currentUser,
                this.email,
                this.firstName,
                this.firstName,
              );
              const users = {
                id: this.colleaguesList[user].email,
                firstName: this.colleaguesList[user].firstname,
                lastName: this.colleaguesList[user].lastname,
                role: this.colleaguesList[user].role,
                message: this.message,
              };
              usersApi.methods
                .createUserByAdmin({ users: [users] })
                .then(async (data) => {
                  if (data.length) {
                    this.step = 2;
                    this.loading = false;
                  }
                })
                .catch((err) => {
                  this.step = 2;
                  this.failedEntries.push(users);
                  console.log(err);
                  console.log('error while adding');
                  this.loading = false;
                });
            }
          } else {
            setTimeout(() => {
              const element = this.$el.querySelector(`.v-messages__message`);

              if (element)
                element.scrollIntoView({
                  behavior: 'smooth',
                  block: 'end',
                });
            }, 100);
          }
        }, 500);
      }
    },
    addColleague(from) {
      if (this.colleaguesList.length < 10 && this.planLicenseAvailable > 0) {
        this.colleaguesList.push({
          email: '',
          firstname: '',
          lastname: '',
          role: '',
          loading: false,
          existError: '',
        });

        setTimeout(() => {
          const element = this.$el.querySelector(
            `#name-input-${this.colleaguesList.length - 1}`,
          );

          if (element) {
            if (from === 'enter') {
              element.focus();
            }
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
            });
          }
        }, 100);
      }
    },
    addMetaToUserObject(user) {
      let status;
      if (
        this.currentActiveSubscription &&
        this.currentActiveSubscription.isExpired
      ) {
        status = 'Expired';
      } else if (user.active) {
        status = user.seenOrSkippedWelcome ? 'Active' : 'Not signed in';
      } else {
        status = 'Disabled';
      }
      const userlevel = {
        username: user.cognitoID,
        name: user.fullName,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.id,
        accessLevel: getFullName(user.role),
        status,
        defaultTheme: user.defaultTheme ? user.defaultTheme : '',
        profileImageUrl: user.profileImageUrl || '',
        limit: user.downloadLimit,
        used: user.downloadUsed,
        efficiencyRating: user.efficiencyRating ? user.efficiencyRating : -1,
        effectiveRating: user.effectiveRating ? user.effectiveRating : -1,
      };
      return userlevel;
    },
    removeColleague(index) {
      if (this.colleaguesList.length > 1) {
        this.colleaguesList = this.colleaguesList.filter((_, i) => i !== index);
      }
    },
    handleEmailChange(e, index) {
      const {
        target: { value },
      } = e;
      this.colleaguesList[index].email = value.toLowerCase();
      this.emailTextChange(value.toLowerCase(), index);
    },
    handleContinue() {
      if (this.planLicenseAvailable === 0) {
        this.$emit('addLicence');
      } else {
        this.step = 1;
        if (this.planLicenseAvailable === 1) {
          this.colleaguesList = [
            {
              email: '',
              firstname: '',
              lastname: '',
              role: '',
              existError: '',
              loading: false,
            },
          ];
        } else {
          this.colleaguesList = [
            {
              email: '',
              firstname: '',
              lastname: '',
              role: '',
              existError: '',
              loading: false,
            },
            {
              email: '',
              firstname: '',
              lastname: '',
              role: '',
              existError: '',
              loading: false,
            },
          ];
        }
      }
    },
    isEmailDomainSame(v) {
      if (v) {
        const domain = this.currentUser.user.id.split('@')[1];
        const colleagueDomain = v.split('@')[1];
        return domain === colleagueDomain;
      }
      return true;
    },
    isDuplicateEmail(v) {
      if (v) {
        return !(
          this.colleaguesList.filter((item) => item.email === v).length > 1
        );
      }

      return true;
    },
    async emailTextChange(v, index) {
      if (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v,
        )
      ) {
        this.colleaguesList[index].loading = true;
        await getExsitingAdminTeamUser(v)
          .then((resp) => {
            if (resp.data.exist) {
              this.colleaguesList[index].existError =
                'Your colleague is already a user. Enter another colleague.';
            } else {
              this.colleaguesList[index].existError = '';
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.colleaguesList[index].loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';

.modal-container {
  background: white;
  border-radius: 15px;
  position: relative;
  padding: 35px;

  .add-user-title {
    color: $dark-default;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.02em;
  }

  .close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 20px 10px;
  }

  .email-list-container {
    max-height: 300px;
    overflow-y: scroll;
    .row {
      align-items: center;
      flex-wrap: nowrap;
    }
    .name-col {
      padding: 0;
      min-width: 22%;
      margin-right: 10px;

      .message-label {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .email-col {
      margin-right: 10px;
      padding: 0;
      min-width: 32%;

      .message-label {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .role-col {
      margin-right: 10px;
      min-width: 15%;
      padding: 0;

      .message-label {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .close-col {
      margin-right: 10px;
      min-width: 5%;
    }
  }
  .no-more-license {
    font-size: 20px;
    opacity: 0.5;
  }
  .add-btn,
  .col {
    padding: 0;
  }
  .textarea-container {
    width: 100%;
    margin: 20px 0 10px 0;

    .message-label {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: bold;
    }
    .textarea {
      min-width: 100%;

      textarea {
        box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
        padding: 10px;
        height: 75px;
        width: 100%;
      }
    }
  }

  .v-btn--disabled.v-btn--has-bg {
    background-color: rgba(0, 0, 0, 0.2) !important;
    color: rgba(255, 255, 255, 0.6) !important;
  }

  .image-invite {
    width: 80%;
    margin: 20px auto;
  }

  .align-center {
    display: flex;
    justify-content: center;
  }

  .space-between {
    display: flex;
    justify-content: space-between;
  }

  .item-row {
    margin: -6px -12px;
    font-size: 16px;
    align-items: center;
    text-transform: none;
  }

  .image-success-invite {
    width: 40%;
    margin: 20px auto;
  }

  .share-btn {
    margin-top: 10px;
    padding: 6px 24px;
    text-transform: none;
  }
  .add-btn {
    font-size: 16px;
    text-transform: none;
    letter-spacing: normal;
  }

  .row {
    width: 100%;
    margin: 0%;
  }
  .email-send {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }
  .add-more-users-btn {
    font-weight: 700;
    text-align: center;
  }
  .failed-users {
    padding: 0px 30px;
    .users-list {
      max-height: 200px;
      overflow: scroll;
      p {
        margin-bottom: 10px;
        span {
          padding: 0px 1px;
        }
      }
    }
  }
}
</style>
